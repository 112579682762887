import axios from "./axios";
//auth
export const login = (data) =>
  axios({
    method: "post",
    url: `/login`,
    data: data,
  });

//profile
export const showLab = () =>
  axios({
    method: "get",
    url: `/profile`,
  });
export const updateLab = (lab_id, data) =>
  axios({
    method: "put",
    url: `/${lab_id}`,
    data,
  });
export const deleteLabImage = (lab_id, id) =>
  axios({
    method: "delete",
    url: `/${lab_id}/images/${id}`,
  });

//lab tests
export const getLabTests = (lab_id, page, params) =>
  axios({
    method: "get",
    url: `/${lab_id}/analyses`,
    params: {
      page,
      params,
    },
  });
export const createLabTest = (lab_id, data) =>
  axios({
    method: "post",
    url: `/${lab_id}/analyses`,
    data,
  });
export const updateLabTest = (lab_id, id, data) =>
  axios({
    method: "put",
    url: `/${lab_id}/analyses/${id}`,
    data,
  });
export const deleteLabTest = (lab_id, id) =>
  axios({
    method: "delete",
    url: `/${lab_id}/analyses/${id}`,
  });

// lab orders
export const getLabOrders = (page, date, status) =>
  axios({
    method: "get",
    url: `/lab-orders`,
    params: {
      page,
      // date,
      status,
    },
  });
export const showLabOrder = (id) =>
  axios({
    method: "get",
    url: `/lab-orders/${id}`,
  });
export const markLabOrderAsPaied = (id) =>
  axios({
    method: "put",
    url: `/lab-orders/${id}/mark-order-as-paid`,
  });
export const markLabOrderAsFinished = (id) =>
  axios({
    method: "put",
    url: `/lab-orders/${id}/mark-order-as-finished`,
  });
export const attatchFileToAnalysis = (data) =>
  axios({
    method: "post",
    url: `/lab-orders/attach-file-to-analysis`,
    data,
  });
export const deleteAttatchFileToAnalysis = (id) =>
  axios({
    method: "delete",
    url: `/lab-orders/delete-file-from-analysis/${id}`,
  });

//governorates
export const getGovernorates = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/governorates`,
    params: {
      page,
      pagination,
    },
  });

//cities
export const getCities = (page, governorate_id, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/cities`,
    params: {
      page,
      governorate_id,
      pagination,
    },
  });

// insurance-companies
export const getCompanies = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/insurance-companies`,
    params: {
      page,
      pagination,
    },
  });

// professional-titles
export const getProfessionalTitles = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/professional-titles`,
    params: {
      page,
      pagination,
    },
  });

// titles
export const getTitles = (page) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/titles`,
    params: {
      page,
    },
  });

// languages
export const getLanguages = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/languages`,
    params: {
      page,
      pagination,
    },
  });

// medical-specialties
export const getMedicalSpecialties = (pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/medical-specialties`,
    params: {
      pagination,
    },
  });

// lab service
export const getLabSpecialties = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/lab-specialties`,
    params: {
      page,
      pagination,
    },
  });

// home nursing
export const getHomeNursingList = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/nursing-specialities`,
    params: {
      page,
      pagination,
    },
  });

// offer specialties
export const getOffersSpecialtiesList = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/offer-specialties`,
    params: {
      page,
      pagination,
    },
  });

// home vaccination
export const getHomeVaccinationList = (page, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/vaccinations`,
    params: {
      page,
      pagination,
    },
  });
// sub specialties
export const getSubLabSpecialties = (page, medical_specialty_id, pagination) =>
  axios({
    method: "get",
    baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/users`,
    url: `/sub-medical-specialties`,
    params: {
      page,
      medical_specialty_id,
      pagination,
    },
  });

//patients
export const getPatients = (page, filters) =>
  axios({
    method: "get",
    url: `/patients`,
    params: {
      page,
      ...filters,
    },
  });
export const getPatientMedicalFile = (id) =>
  axios({
    method: "get",
    url: `/patients/${id}/show-profile`,
  });

//offers
export const getOffers = (page, { admin_status }) =>
  axios({
    method: "get",
    url: `/offers`,
    params: {
      page,
      admin_status,
    },
  });
export const showOffer = (id) =>
  axios({
    method: "get",
    url: `/offers/${id}`,
  });

export const createOffer = (data) =>
  axios({
    method: "post",
    url: `/offers`,
    data,
  });

export const updateOffer = (id, data) =>
  axios({
    method: "put",
    url: `/offers/${id}`,
    data,
  });
export const deleteOffer = (id) =>
  axios({
    method: "delete",
    url: `/offers/${id}`,
  });

export const deleteOfferImage = (offer_id, id) =>
  axios({
    method: "delete",
    url: `/offers/${offer_id}/gallery/${id}`,
  });
