
export const setStorage = (name, value, days) => {

    localStorage.setItem(name, value)
}
export const getStorage = (cname) => {

    return localStorage.getItem(cname)
}
export const deleteStorage = (name) => {
    localStorage.removeItem(name);
};
export const checkStorage = (c_name) => {
    if (localStorage.getItem(c_name) === null) {
        return false
    }
    else {
        return true
    }

} 