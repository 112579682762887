import { WINDOW_RESIZE, TOGGLE_SIDEBAR } from "./types";
export const resizeWindow = (window_size) => (dispatch) => {
  dispatch({
    type: WINDOW_RESIZE,
    payload: window_size,
  });
};

export const toggleSidebar = (sidebar_open) => (dispatch) => {
  dispatch({
    type: TOGGLE_SIDEBAR,
    payload: sidebar_open,
  });
};
