import { WINDOW_RESIZE, TOGGLE_SIDEBAR } from '../actions/types'
const initial_state = {
    window_width: window.innerWidth,
    sidebar_open: false
}

export default function (state = initial_state, action) {
    switch (action.type) {
        case WINDOW_RESIZE:
            return {
                ...state,
                window_width: action.payload
            }
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                sidebar_open: action.payload
            }
        default:
            return state
    }
}